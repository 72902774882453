@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/material-design-icons/MaterialIconsOutlined-Regular.otf"); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIconsOutlined-Regular'),
  url("../fonts/material-design-icons/MaterialIconsOutlined-Regular.otf") format('opentype');
}

.material-icons {
  vertical-align: top;
  font-family: 'Material Icons', serif;
  font-weight: normal;
  font-style: normal;
  //font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}