@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro'), url('../fonts/source-sans-pro/SourceSansPro-Regular.ttf') format('ttf');
}

@tailwind utilities;

@import "app";

#jsd-widget {
    z-index: 69420;
    bottom: 1rem !important;
    max-height: calc(100vh - 1rem);
}
@media all and  (min-width:768px){
    #jsd-widget {
        bottom: 1.5rem !important;
    }
}

@layer base {
    @import 'themes/themes.css', 'themes/apply.css';
}