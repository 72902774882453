$tab-nav-scrollbar-height: 0.4rem;
$tab-nav-scrollbar-margin-top: 5px;
$tab-nav-scrollbar-color: hsl(210, 31%, 90%);
$tab-nav-thumb-color: hsl(209, 34%, 30%);
$tab-nav-thumb-color-hover: hsla(211, 39%, 23%);
$tab-nav-scrollbar-border-radius: 4rem;

// Styling for scroll bar on overflow x scroll
#tabNavDesktop {

  // Webkit
  &::-webkit-scrollbar {
    height: $tab-nav-scrollbar-height;
    background-color: $tab-nav-scrollbar-color;
    border-radius: $tab-nav-scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $tab-nav-thumb-color;
    border-radius: $tab-nav-scrollbar-border-radius;
    transform: translateY(5px);
  }

  // Firefox ready when they are ;)
  &::-moz-scrollbar {
    height: $tab-nav-scrollbar-height;
    background-color: $tab-nav-scrollbar-color;
    border-radius: $tab-nav-scrollbar-border-radius;
  }
  &::-moz-scrollbar-thumb {
    background-color: $tab-nav-thumb-color;
    border-radius: $tab-nav-scrollbar-border-radius;
  }

}


#tabNavDesktop::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
  background-color: $tab-nav-thumb-color-hover;

}

#tabNavDesktop::-moz-scrollbar-thumb:hover {
  cursor: pointer !important;
  background-color: $tab-nav-thumb-color-hover;

}